import React from "react";
import {useNavigate } from "react-router-dom";
import NotFoundSvg from "./NotFoundSvg";

export default function NotFound() {
  const navigate = useNavigate();
  const navigateToNotFound = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="d-flex justify-content-center svgheight">
        <NotFoundSvg />
      </div>
      <span className="d-flex justify-content-center fs-3 fw-bold">
        Oops! Why you’re here?
      </span>
      <div className="d-flex justify-content-center text-center py-2">
        We are very sorry for inconvenience. It looks like you’re try to access
        a <br /> page that either has been deleted or never existed.
      </div>
      <div className=" d-flex justify-content-center py-4">
        <button
          type="button"
          className="btn btn-danger d-flex align-items-center"
          onClick={() => navigateToNotFound()}
        >
          <span className="text-light btntext">Back To Home</span>
        </button>
      </div>
    </>
  );
}
