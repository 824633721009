import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getCookie } from "./Utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import NoResultsImage from "../Images/NoResultFound.png";
import {
  FaArrowLeftLong,
  FaChevronLeft,
  FaChevronRight,
  FaCompress,
  FaExpand,
} from "react-icons/fa6";

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaDownload,
  FaList,
  FaPause,
  FaPlay,
  FaSearchMinus,
  FaSearchPlus,
  FaTh,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";

import { Document, Page, pdfjs } from "react-pdf";
import PageFlip from "react-pageflip";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { useParams } from "react-router-dom";
import flipSound from "../Sound/page-flip.ogg";
import { FiShoppingBag } from "react-icons/fi";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ModalTitle } from "react-bootstrap";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { RiCloseLine } from "react-icons/ri";
import { PDFDocument, rgb } from "pdf-lib";
import { getDocument } from "pdfjs-dist";
import HTMLFlipBook from "react-pageflip";
import { Oval } from "react-loader-spinner";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@latest/es5/build/pdf.worker.min.js";

function ViewPublisher() {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [pages, setPages] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [data, setData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playInterval, setPlayInterval] = useState(null);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [apiFileName, setAPIFileName] = useState(null);
  const pageFlipRef = useRef(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [show, setShow] = useState(false);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [audio] = useState(new Audio(flipSound));
  const { pdf_id } = useParams();
  const flipIntervalRef = useRef(null);
  const bookRef = useRef(null);

  useEffect(() => {
    fetchRecentData();
  }, []);
  const [bookSize, setBookSize] = useState({ width: 400, height: 500 });

  const calculateBookSize = (aspectRatio) => {
    return aspectRatio > 1
      ? { width: 600, height: 400 }
      : { width: 400, height: 500 };
  };
  const fetchRecentData = async () => {
    try {
      const formData = new FormData();

      formData.append("flipbook_id", pdf_id);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publisher-profile`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("option1");

  const handleThumbnailClick = (index) => {
    bookRef.current.pageFlip().turnToPage(index);
  };

  const togglebuymodal = () => {
    setShowBuyModal(!showBuyModal);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const navigateToView = (id) => {
    navigate(`/publish/view/${id}`);
  };

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handlePrevPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipPrev();
    }
  };

  const handleNextPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipNext();
    }
  };

  const handleFirstPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().turnToPage(0);
    }
  };

  const handleLastPage = () => {
    if (bookRef.current) {
      const totalPages = bookRef.current.pageFlip().getPageCount();
      bookRef.current.pageFlip().turnToPage(totalPages - 1);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        bookRef.current.pageFlip().flipNext();
      }, 1500); // Change the interval time as needed
      setPlayInterval(intervalId);
    } else {
      clearInterval(playInterval);
    }

    return () => clearInterval(playInterval);
  }, [isPlaying]);

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);

  const downloadClick = async () => {
    console.log("PDF URL:", pdfUrl);

    const watermarkText =
      process.env.REACT_APP_TITLE === "1"
        ? "Powered By SiloCloud"
        : "Powered By AxiomWebControl";
    const projectLink =
      process.env.REACT_APP_TITLE === "1"
        ? "https://publisher.silocloud.io"
        : "https://publisher.axiomwebcontrol.com/";

    const loadingToastId = toast.loading("Preparing your download...");

    try {
      // Fetch the existing PDF
      const existingPdfBytes = await fetch(pdfUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch PDF");
        return res.arrayBuffer();
      });

      // Load the existing PDF document
      const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
      const numPages = existingPdfDoc.getPageCount(); // Get number of pages

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Copy pages from the existing PDF and apply the watermark
      for (let i = 0; i < numPages; i++) {
        const [page] = await pdfDoc.copyPages(existingPdfDoc, [i]);
        const { width, height } = page.getSize();

        // Add watermark text in faint red
        page.drawText(watermarkText, {
          x: 15,
          y: 20,
          size: 14,
          color: rgb(1, 0, 0), // Faint red
          opacity: 0.3,
        });

        // Add project link in blue below the watermark text
        page.drawText(projectLink, {
          x: 15,
          y: 8,
          size: 10,
          color: rgb(0, 0, 1), // Blue
          opacity: 0.8,
        });

        // Add the modified page to the new PDF document
        pdfDoc.addPage(page);
      }

      // Save the modified PDF
      const pdfBytes = await pdfDoc.save();

      // Trigger download
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `silo-${apiFileName}`;
      link.click();

      // Dismiss the loading toast and show success message
      toast.dismiss(loadingToastId);
      toast.success("Download complete!");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.dismiss(loadingToastId);
      toast.error("Failed to download PDF.");
    }
  };

  useEffect(() => {
    fetchDatafolder();
    fetchApiPdf();
  }, []);

  useEffect(() => {
    const loadPages = async () => {
      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        const canvas = await document.querySelector(
          `.demoPage canvas:nth-child(${i})`
        );
        pagesArray.push(canvas?.toDataURL("image/jpeg"));
      }
      setPages(pagesArray);
    };
    if (numPages) loadPages();
  }, [numPages]);

  // const { pdf_id } = useParams();
  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApiPdf = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-tempurl?pdf_id=${pdf_id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      // const newdata = await responseData.data.pdf_list[0].pdf_file;
      const newdata = await responseData.data.pdf_file_url;
      const newFile = await responseData.data.file_name;
      setpdfUrl(newdata);
      setAPIFileName(newFile);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      console.log("Fetching PDF from URL:", pdfUrl);
      setLoading(true);
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch PDF");
          }
          return response.arrayBuffer();
        })
        .then(async (pdfData) => {
          const pdf = await getDocument({ data: pdfData }).promise;
          const totalPages = pdf.numPages;
          const thumbnailPromises = [];

          for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            if (pageNum === 1) {
              const { width, height } = page.getViewport({ scale: 0.5 });
              const aspectRatio = width / height;
              const newSize = calculateBookSize(aspectRatio);
              setBookSize(newSize);
            }

            thumbnailPromises.push(generateThumbnail(pdf, pageNum));
          }

          const generatedThumbnails = await Promise.all(thumbnailPromises);
          setThumbnails(generatedThumbnails);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
          setLoading(false);
        });
    }
  }, [pdfUrl]);

  const generateThumbnail = async (pdf, pageNum) => {
    try {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      const base64Data = canvas.toDataURL("image/png");
      console.log(`Generated thumbnail for page ${pageNum}`);
      return { pageNum, thumbnail: base64Data };
    } catch (error) {
      console.error(`Error generating thumbnail for page ${pageNum}:`, error);
      return { pageNum, thumbnail: null };
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const pdfData = useSelector((state) => state.pdfUrl.pdfUrl);

  useEffect(() => {
    const handleFlip = () => {
      if (audioRef.current && !isMuted) {
        audioRef.current.play();
      }
    };

    if (bookRef.current) {
      bookRef.current.pageFlip().on("flip", handleFlip);
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [isMuted]);

  useEffect(() => {
    const data = pdfData.dataUri;

    setFileData(data);

    if (data && data?.startsWith("data:application/pdf")) {
      const base64 = data.split(",")[1]; // Remove the prefix
      const pdfData = atob(base64);
      const loadingTask = pdfjsLib.getDocument({ data: pdfData });
      loadingTask.promise
        .then((pdf) => {
          const numPages = pdf.numPages;
          const loadedPages = [];
          const renderPage = (pageNum) => {
            pdf.getPage(pageNum).then((page) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext).promise.then(() => {
                loadedPages.push(canvas?.toDataURL());
                if (loadedPages.length === numPages) {
                  setPages(loadedPages);
                  setIsLoading(false); //shimmer before display the flipbook
                } else {
                  renderPage(pageNum + 1);
                }
              });
            });
          };
          renderPage(1);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    } else {
      console.error("Invalid or missing PDF data");
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [navigate]);

  // Upload Publisher -API
  const publishClick = async () => {
    const toastId = toast.loading("Publishing...");
    try {
      const base64 = fileData.split(",")[1];
      const formData = new FormData();
      formData.append("pdf_file", base64ToBlob(base64));
      formData.append("title", input);
      formData.append("flipbook_id", pdf_id);
      formData.append("visibility", 2);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publish`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const pdfs = responseData?.data?.fileData || [];
      setData(pdfs);

      toast.update(toastId, {
        render: "Publication successful!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.update(toastId, {
        render: "Publication failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      console.error("Error converting base64 to Blob:", error);
      return null;
    }
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        {
          <>
            <div className="book-container">
              <div className="book">
                <div className="page page-left">
                  <div className="shimmer"></div>
                </div>
                <div className="page page-right">
                  <div className="shimmer"></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    );
  }

  //Calcution for Total Amount
  const totalPrice = data?.publisher_data?.price;
  const augerFeePercent = data?.token_data?.auger_fee_percent;
  const augerFee = ((totalPrice * augerFeePercent) / 100).toFixed(2);
  const totalAmount = Number(totalPrice) + Number(augerFee);

  //addPayment API

  async function addPayment(payload) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/payment/add`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authToken: getCookie("authToken", undefined),
      },
      body: JSON.stringify(payload),
    });

    const res = await response.json();
    if (!res.status) {
      // const errorData = await response.json();
      const error = new Error("Failed to make payment.", error);
      // error.info = errorData;
      error.code = `${res.status}`;
      throw error;
    }
    return res;
  }

  //Paypal Integration
  const initialOptions = {
    "client-id":
      "AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R",
    currency: "USD",
    intent: "capture",
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: totalAmount,
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      const payload = {
        purpose: 1,
        mode: 2,
        buyTokens: 1,
        payment_response: JSON.stringify(details),
      };
      const response = await addPayment(payload);
      if (response.status) {
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error capturing order: ", error);
    }
  };

  return (
    <>
      <div
        className="container-fluid viewheight py-2"
        style={{ overflow: "hidden" }}
      >
        {loading && thumbnails.length <= 0 ? (
          <div className="d-flex justify-content-center align-items-center viewLoading">
            <div className="uploadloader">
              <Oval
                visible={true}
                height="100"
                width="100"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <div className="py-3">Loading....</div>
            </div>
          </div>
        ) : (
          <div className="publish-flipbook-container py-2">
          
            <div
              style={{ transform: `scale(${zoomLevel})`, position: "relative" }}
              className="flipbook-wrapper4"
              
            >
               {showTableOfContents && (
              <div className="thumbnails-sidebar-publish1">
                {thumbnails.map((thumbnailObj, index) => (
                  <div key={index} className="thumbnail-container">
                    <img
                      src={thumbnailObj.thumbnail} // Access the 'thumbnail' property here
                      alt={`Page ${thumbnailObj.pageNum}`}
                      className="thumbnail"
                      onClick={() => handleThumbnailClick(index)}
                    />
                    <div className="page-number">{thumbnailObj.pageNum}</div>
                  </div>
                ))}
              </div>
            )}
              <HTMLFlipBook
                width={bookSize.width}
                height={bookSize.height}
                ref={bookRef}
                onFlip={handleFlip}
                mobileScrollSupport={true}
                showCover={true}
                autoSize={true}
              >
                {thumbnails.map((thumbnailObj, index) => (
                  <div key={index} className="demoPage">
                    {thumbnailObj.thumbnail ? (
                      <img
                        src={thumbnailObj.thumbnail}
                        alt={`PDF Thumbnail ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <div className="thumbnail-placeholder">
                        Page {thumbnailObj.pageNum}
                      </div>
                    )}
                  </div>
                ))}
              </HTMLFlipBook>
            </div>
            <div
              className={
                process.env.REACT_APP_TITLE === "1"
                  ? "toolbox-view d-flex justify-content-center"
                  : "toolbox-view d-flex justify-content-center"
              }
            >
              <div className="tool3 text-center" onClick={handleFirstPage}>
                <FaAngleDoubleLeft />
              </div>
              <div
                className="tool3 text-center"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </div>
              <div
                className="tool3 text-center"
                onClick={handleNextPage}
                disabled={currentPage === numPages}
              >
                <FaChevronRight />
              </div>
              <div className="tool3 text-center" onClick={handleLastPage}>
                <FaAngleDoubleRight />
              </div>
              <div
                className="tool3 text-center"
                onClick={toggleTableOfContents}
              >
                {showTableOfContents ? <FaTh /> : <FaList />}
              </div>
              <div className="tool3 text-center" onClick={zoomIn}>
                <FaSearchPlus />
              </div>
              <div className="tool3 text-center" onClick={zoomOut}>
                <FaSearchMinus />
              </div>
              <div className="tool3 text-center" onClick={togglePlayPause}>
                {isPlaying ? <FaPause /> : <FaPlay />}
              </div>
              <div className="tool3 text-center" onClick={toggleMute}>
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
              </div>
              <div className="tool3 text-center" onClick={toggleFullScreen}>
                {isFullScreen ? <FaCompress /> : <FaExpand />}
              </div>
              <div className="tool3 text-center" onClick={downloadClick}>
                <FaDownload />
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid pt-5 d-flex justify-content-between ">
          <div>
            <h4 className="publicationtitle">{data?.publisher_data?.title}</h4>
            <div className="publicationsdate">
              <span className="pr-2 fs-6 text-primary">Published On</span>
              {data?.publisher_data?.published_date}
            </div>
          </div>
          <div className="d-flex">
            <div className="px-2">
              <button
                type="button"
                class="btn btn-outline-danger"
                onClick={togglebuymodal}
              >
                <FiShoppingBag />
                <span className="px-2">Buy</span>
              </button>
            </div>
            
            <Link to="/browse">
              <div className="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-secondary">
                  <FaArrowLeftLong />
                  <span className="px-2">Back</span>
                </button>
              </div>
            </Link>
          </div>
        </div>
        <hr className="px-5 hrline" />
        <div className="profile-pic d-flex justify-content-start container-fluid">
          <div className="profilecircle">
            <img
              src={data?.publisher_data?.profile_image_path}
              // src={profileimage}
              alt=""
              className="roundedcircle1"
              width={40}
            />
          </div>
          <div className="username d-flex align-items-center fs-5 fw-bold">
            {data?.publisher_data?.username}
          </div>
        </div>

        <div className="container-fluid py-5">
          <div className="container-fluid">
            <p className="morefrom">
              {" "}
              More from{" "}
              <span className="text-primary">
                {data?.publisher_data?.username}
              </span>
            </p>
          </div>
          <div className="container-fluid profile-container">
            <div className="row">
              {data &&
              data?.publisher_data?.publications_for_sale.length > 0 ? (
                data?.publisher_data?.publications_for_sale?.map(
                  (item, idx) => (
                    <div className="col-md-2">
                      <div
                        className="card w-100"
                        onClick={() => navigateToView(item.flipbook_id)}
                      >
                        <img
                          src={item?.thumbnail}
                          class="card-img-top"
                          alt="..."
                        />
                      </div>
                      <div className="publicationstext pt-2">{item?.title}</div>
                      <div className="publicationsdate ">
                        {item.published_date}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="col-12 text-center p-5">
                  <img
                    src={NoResultsImage}
                    height={200}
                    alt="No results found"
                    className="no-results-image"
                  />
                  <p>No results found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showBuyModal} toggle={togglebuymodal} centered>
        <ModalHeader>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <ModalTitle
              className="mb-0"
              style={{ fontWeight: "normal", color: "#2F3A4B" }}
            >
              Payment
            </ModalTitle>
            <RiCloseLine className="crossicon" onClick={togglebuymodal} />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="buyOptions"
                id="option1"
                value="option1"
                onChange={handleOptionChange}
                checked={selectedOption === "option1"}
              />
              <label className="form-check-label" htmlFor="option1">
                Paypal
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input px-2"
                type="radio"
                name="buyOptions"
                id="option2"
                value="option2"
                onChange={handleOptionChange}
                checked={selectedOption === "option2"}
              />
              <label className="form-check-label" htmlFor="option2">
                Account Tokens
              </label>
            </div>
          </div>
          {selectedOption === "option1" && (
            <>
              <div className="mt-3">
                <p className="text-center">
                  <span className="paymenttext">Live Token Rate 1 SBC ≈</span>{" "}
                  $0.000417
                </p>
                <p className="text-center">
                  <span className="paymenttext">Purchase Amount($):</span>
                  {totalAmount}
                </p>
              </div>
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  fundingSource="paypal"
                  className="px-5"
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={(err) => console.log(err)}
                />
              </PayPalScriptProvider>
            </>
          )}
          {selectedOption === "option2" && (
            <>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <p>
                    <span className="paymenttext">Available Tokens</span>:
                    0.000000
                  </p>
                  <p>
                    <span className="paymenttext">Auger Fee(2.9%)</span>:
                    1319.909178
                  </p>
                </div>
                <p>
                  <span className="paymenttext">Required Tokens</span>:
                  46834.018767
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-end">
                <div className="px-2">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onChange={handleOptionChange}
                  >
                    Purches Tokens
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={togglebuymodal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ViewPublisher;
