import React from "react";
import { Link } from "react-router-dom";

export default function Sales() {
  return (
    <>
      <div className="container">
        <div className="sales-container">
          <div className="sales-heading">
            Start selling <br />
            your content
          </div>
          <div className=" sales-subheading py-2">
            about how you can monetize what <br />
            you create on publisher! Upgrade to Publisher Premium <br />
            to start selling access to your digital content.
          </div>

          <Link
            to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions/service-plan/buy/8`}
          >
            <div class="d-flex justify-content-start py-3 ">
              <button class="btn btn-primary px-5 col-3 fw-700" type="button">
                Upgrade
              </button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
