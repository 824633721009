import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import "./../CSS/Header.css";
import { Dropdown, DropdownToggle, DropdownMenu, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import moment, { unix } from "moment";
import {
  getAllNotificationHandler,
  setNotificationCountHandler,
} from "../utils/api_helper.jsx";

import { FiCornerDownRight, FiCornerDownLeft } from "react-icons/fi";
import { formatDateTime } from "./Utils";

import { useTheme } from "../Context/ThemeContext";

import { HiBell } from "react-icons/hi2";
import NotificationSkeletonLoader from "./NotificationSkeletonLoader.jsx";

const Icon = ({ name, className }) => {
  return <i className={`icon ${name} ${className}`}></i>;
};

const Notifi = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const toggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function NotificationItem({
    title,
    time,
    isSender,
    link,
    updatedAt,
    recent,
    index,
    notification,
  }) {
    const [isOpen, setIsOpen] = useState(false);
    const EventToggle = () => setIsOpen(!isOpen);
    const Icon = isSender ? FiCornerDownRight : FiCornerDownLeft;
    const iconName = isSender ? "curve-down-right" : "curve-down-left";
    const bgColor = isSender ? "text-danger" : "text-success";

    const handleModuleNotifications = (notification) => {
      switch (notification.module) {
        case "6":
          setIsOpen(true);
          console.log(notification.reference_id);
          break;
        default:
          console.log(`Component Not Available!...`);
          break;
      }
    };
    // console.log("This is updated" + updatedAt);

    return (
      <>
        <li
          className="p-3 web-app"
          onClick={() => {
            handleModuleNotifications(notification);
          }}
        >
          <div className=" justify-content-between align-items-start w-100">
            <div className="d-flex align-items-center gap-2">
              <div
                className="nk-notification-icon icon-circle"
                // style={{backgroundColor:"#fcedee"}}
                style={{
                  backgroundColor: theme === "dark" ? "#202a47" : "#fcedee  ",
                }}
              >
                <Icon name={iconName} className={` ${bgColor}`} />
              </div>
              <div className="nk-notification-content pointer">
                <div
                  className="nk-notification-text"
                  style={{
                    color: theme === "dark" ? "#b6c6e3" : "#526484  ",
                  }}
                >
                  {title}
                </div>
                <div
                  className=" nk-notification-time"
                  style={{ fontWeight: "400" }}
                >
                  {formatDateTime(updatedAt)}
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }

  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({
    notifications: [],
  });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const toggle = () => {
  //   setOpen((prevState) => !prevState);
  // };

  // Fetch notifications
  // const {
  //   data: allNotificationData,
  //   isLoading: allNotificationIsLoading,
  //   isRefetching: allNotificationIsRefetching,
  //   isError: allNotificationIsError,
  //   error: allNotificationError,
  //   refetch: allNotificationRefetch,
  // } = useQuery({
  //   queryKey: ["get-all-notification", limit],
  //   queryFn: async () => getAllNotificationHandler({ limit: limit }),
  //   gcTime: 0,
  //   staleTime: Infinity,
  // });

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getAllNotificationHandler({ limit: 17 });
      if (response.status) {
        const unReadNotifications = Array.isArray(response.data.notifications)
          ? response.data.notifications.filter(
              (notification) => notification.seen === "0"
            )
          : [];
        setNotificationCount(unReadNotifications.length || 0);

        const newNotifications = response.data.notifications.filter(
          (notification) => {
            return !notificationData.notifications.some(
              (existingNotification) =>
                existingNotification.id === notification.id
            );
          }
        );

        setNotificationData((prevNotification) => ({
          notifications: [
            ...prevNotification.notifications,
            ...newNotifications,
          ],
        }));

        const notificationsId = response.data.notifications.map(
          (notification) => notification.id
        );
        setNotificationIds(notificationsId);
      }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [limit]);

  // Mark all notifications as read mutation
  // const { isPending: markAllReadIsPending, mutate: markAllReadMutate } =
  //   useMutation({
  //     mutationKey: ["mark-all-as-read"],
  //     mutationFn: setNotificationCountHandler,
  //     onSuccess: (data) => {
  //       if (data.toast) {
  //         if (!data.status) {
  //           toast.error(data?.message);
  //           if (data?.redirect) {
  //             console.log(process.env.REACT_APP_ACCOUNT_LOGIN_URL);
  //             // window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
  //           }
  //         } else {
  //           // allNotificationRefetch();
  //           toast.success(data?.message);
  //         }
  //       }
  //     },
  //   });

  // useEffect(() => {
  //   if (!allNotificationIsLoading) {
  //     if (allNotificationData && allNotificationData.status) {
  //       const unReadNotifications = Array.isArray(
  //         allNotificationData.data?.notifications
  //       )
  //         ? allNotificationData.data.notifications.filter(
  //             (notification) => notification.seen === "0"
  //           )
  //         : [];
  //       setNotificationCount(unReadNotifications.length || 0);

  //       const newNotifications = allNotificationData.data.notifications.filter(
  //         (notification) => {
  //           return !notificationData.notifications.some(
  //             (existingNotification) =>
  //               existingNotification.id === notification.id
  //           );
  //         }
  //       );

  //       setNotificationData((prevNotification) => ({
  //         notifications: [
  //           ...prevNotification.notifications,
  //           ...newNotifications,
  //         ],
  //       }));
  //     } else if (allNotificationData && allNotificationData.redirect) {
  //       // window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
  //     }
  //   }

  //   // eslint-disable-next-line
  // }, [allNotificationData, allNotificationIsLoading]);

  // useEffect(() => {
  //   if (!allNotificationIsLoading && allNotificationData) {
  //     if (notificationData?.status) {
  //       const notificationsId = allNotificationData?.data?.notifications.map(
  //         (notification) => notification.id
  //       );
  //       setNotificationIds(notificationsId);
  //     }
  //   }

  //   // eslint-disable-next-line
  // }, [allNotificationData, allNotificationIsLoading]);

  const markAllRead = async () => {
    try {
      const data = await setNotificationCountHandler({
        notification_id: 0,
        status: 1,
        module: 6,
        
      });
      if (!data.status) {
        toast.error(data?.message);
        // if (data?.redirect) {
        //   window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        // }
      } else {
        toast.success(data?.message);
        setNotificationCount(0);
        // setNotificationData((prevData) => ({
        //   notifications: prevData.notifications.map((notification) => ({
        //     ...notification,
        //     seen: "1",
        //   })),
        // }));
        setNotificationData({ notifications: [] });
        // toggleFetch(loading);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };
  const [timeData, setTimeData] = useState();

  return (
    <Dropdown
      isOpen={open}
      toggle={toggle}
      className={`user-dropdown hero ${theme}`}
      // className={`user-dropdown hero`}
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div
          className={`rounded-circle p-2  align-items-center ${
            open
              ? theme === "dark"
                ? "activeApps2"
                : "activeApps"
              : theme === "dark"
              ? "appicons2"
              : "appicons"
          }`}
        >
          <HiBell
            name="bell-fill "
            className="fs-3 bellicon"
            style={{
              color: "",
            }}
          />
          {notificationCount > 0 && (
            <div color="primary" className="countIcon">
              {loading ? 0 : notificationCount}
            </div>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu
        end
        className=" dropdown-menu rounded-top notifimenu notifibox  p-0 shadow transform-notify"
        style={{
          backgroundColor: theme === "dark" ? "#1B1B1B" : "#FEFFFE  ",
          color: theme === "dark" ? "#B6C6E3" : "#526284",
        }}
      >
        <div className=" d-flex justify-content-between align-items-center  noti_title rounded-top  ">
          <div className="noti_font">Notifications</div>
          <div
            size="small"
            className="noti_font"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (notificationCount <= 0) {
                toast.error("There are no unread messages.");
              } else {
                // markAllReadMutate({ notification_id: 0, status: 1 });
                markAllRead();
              }
            }}
          >
            {/* {markAllReadIsPending ? (
              <Spinner
                size={"sm"}
                color="primary"
                style={{ borderWidth: "1px" }}
              />
            ) : ( */}
            Mark All as Read
            {/* )} */}
          </div>
        </div>

        <div className="dropdown-body">
          <div className="nk-notification dropdownMenu">
            {loading ? (
              <NotificationSkeletonLoader />
            ) : error ? (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: "red",

                  textTransform: "capitalize",
                }}
              >
                {error.message}
              </div>
            ) : notificationData.notifications.length > 0 ? (
              <div className=" border-top">
                {notificationData.notifications.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    title={notification.title}
                    time={moment(notification.created_at).format("DD MMM")}
                    isSender={notification.is_sender}
                    link={notification.link}
                    updatedAt={notification.created_at}
                    recent={notification.recent}
                    notification={notification}
                  />
                ))}

                {!loading && (
                  <div className=" center noti_font d-flex justify-content-center align-items-center noti_title  border-top  ">
                    <div
                      className="pointer"
                      onClick={loadMoreNotifications}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          size="sm"
                          style={{ borderWidth: "1px", color: "inherit" }}
                        />
                      ) : (
                        "View More"
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ padding: "1.25rem", color: "#e14954", fontSize:"13px" }}>
                  No Notification Found
                </div>
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notifi;
