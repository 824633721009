import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PageFlip from "react-pageflip";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { getCookie } from "./Utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import flipSound from "../Sound/page-flip.ogg";
import {
  FaPlay,
  FaPause,
  FaSearchPlus,
  FaSearchMinus,
  FaDownload,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaList,
  FaTh,
  FaChevronRight,
  FaChevronLeft,
  FaRegFolder,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { PDFDocument, rgb } from "pdf-lib";
import { getDocument } from "pdfjs-dist";
import HTMLFlipBook from "react-pageflip";
import { Oval } from "react-loader-spinner";
import { FaArrowLeftLong } from "react-icons/fa6";
import Cookies from "js-cookie";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@latest/es5/build/pdf.worker.min.js";

const PDFFlipBook = () => {
  const [numPages, setNumPages] = useState(null);
  const pageFlipRef = useRef(null);
  const [show, setShow] = useState(false);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [apiFileName, setAPIFileName] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [pages, setPages] = useState([]);
  const [audio] = useState(new Audio(flipSound));
  const [isPlaying, setIsPlaying] = useState(false);
  const [playInterval, setPlayInterval] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pdf_id } = useParams();
  const flipIntervalRef = useRef(null);
  const bookRef = useRef(null);

  const [bookSize, setBookSize] = useState({ width: 400, height: 500 });

  const calculateBookSize = (aspectRatio) => {
    return aspectRatio > 1
      ? { width: 600, height: 400 }
      : { width: 400, height: 500 };
  };

  let authToken = Cookies.get("authToken");

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);

  const handleThumbnailClick = (index) => {
    bookRef.current.pageFlip().turnToPage(index);
  };

  const handlePrevPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipPrev();
    }
  };

  const handleNextPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipNext();
    }
  };

  const handleFirstPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().turnToPage(0);
    }
  };

  const handleLastPage = () => {
    if (bookRef.current) {
      const totalPages = bookRef.current.pageFlip().getPageCount();
      bookRef.current.pageFlip().turnToPage(totalPages - 1);
    }
  };

  useEffect(() => {
    fetchDatafolder();
    fetchApiPdf();
  }, []);

  useEffect(() => {
    const loadPages = async () => {
      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        const canvas = await document.querySelector(
          `.demoPage canvas:nth-child(${i})`
        );
        if (canvas) {
          pagesArray.push(canvas?.toDataURL("image/jpeg"));
        }
      }
      setPages(pagesArray);
    };
    if (numPages) loadPages();
  }, [numPages]);
  useEffect(() => {
    if (numPages && bookRef.current) {
      // You can perform actions that require bookRef.current here
    }
  }, [numPages]);

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApiPdf = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-tempurl?pdf_id=${pdf_id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      const newdata = await responseData.data.pdf_file_url;
      const newFile = await responseData.data.file_name;
      setpdfUrl(newdata);
      setAPIFileName(newFile);
    } catch (error) {
      console.error(error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      console.log("Fetching PDF from URL:", pdfUrl);
      setLoading(true);
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch PDF");
          }
          return response.arrayBuffer();
        })
        .then(async (pdfData) => {
          const pdf = await getDocument({ data: pdfData }).promise;
          const totalPages = pdf.numPages;
          const thumbnailPromises = [];

          for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            if (pageNum === 1) {
              const { width, height } = page.getViewport({ scale: 1 });
              const aspectRatio = width / height;
              const newSize = calculateBookSize(aspectRatio);
              setBookSize(newSize);
            }

            thumbnailPromises.push(generateThumbnail(pdf, pageNum));
          }

          const generatedThumbnails = await Promise.all(thumbnailPromises);
          setThumbnails(generatedThumbnails);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
          setLoading(false);
        });
    }
  }, [pdfUrl]);

  const generateThumbnail = async (pdf, pageNum) => {
    try {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      const base64Data = canvas.toDataURL("image/png");
      console.log(`Generated thumbnail for page ${pageNum}`);
      return { pageNum, thumbnail: base64Data };
    } catch (error) {
      console.error(`Error generating thumbnail for page ${pageNum}:`, error);
      return { pageNum, thumbnail: null };
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        bookRef.current.pageFlip().flipNext();
      }, 1500); // Change the interval time as needed
      setPlayInterval(intervalId);
    } else {
      clearInterval(playInterval);
    }

    return () => clearInterval(playInterval);
  }, [isPlaying]);

  const downloadClick = async () => {
    console.log("PDF URL:", pdfUrl);

    const watermarkText =
      process.env.REACT_APP_TITLE === "1"
        ? "Powered By SiloCloud"
        : "Powered By AxiomWebControl";
    const projectLink =
      process.env.REACT_APP_TITLE === "1"
        ? "https://publisher.silocloud.io"
        : "https://publisher.axiomwebcontrol.com/";

    const loadingToastId = toast.loading("Preparing your download...");

    try {
      // Fetch the existing PDF
      const existingPdfBytes = await fetch(pdfUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch PDF");
        return res.arrayBuffer();
      });

      // Load the existing PDF document
      const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
      const numPages = existingPdfDoc.getPageCount(); // Get number of pages

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Copy pages from the existing PDF and apply the watermark
      for (let i = 0; i < numPages; i++) {
        const [page] = await pdfDoc.copyPages(existingPdfDoc, [i]);
        const { width, height } = page.getSize();

        // Add watermark text in faint red
        page.drawText(watermarkText, {
          x: 15,
          y: 20,
          size: 14,
          color: rgb(1, 0, 0), // Faint red
          opacity: 0.3,
        });

        // Add project link in blue below the watermark text
        page.drawText(projectLink, {
          x: 15,
          y: 8,
          size: 10,
          color: rgb(0, 0, 1), // Blue
          opacity: 0.8,
        });

        // Add the modified page to the new PDF document
        pdfDoc.addPage(page);
      }

      // Save the modified PDF
      const pdfBytes = await pdfDoc.save();

      // Trigger download
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `silo-${apiFileName}`;
      link.click();

      // Dismiss the loading toast and show success message
      toast.dismiss(loadingToastId);
      toast.success("Download complete!");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.dismiss(loadingToastId);
      toast.error("Failed to download PDF.");
    }
  };

  const loadPdfThumbnails = async (fileData) => {
    try {
      setLoading(true);
      setThumbnails([]);

      const loadingTask = getDocument({ data: fileData });
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      const thumbnailList = [];

      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 0.5 });

        // Create a new canvas for each page
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the page to the new canvas
        await page.render({ canvasContext: context, viewport }).promise;

        // Get the image data URL from the canvas
        const thumbnail = canvas?.toDataURL("image/png", 1.0);
        thumbnailList.push(thumbnail);

        // Calculate the aspect ratio from the first thumbnail
        if (pageNumber === 1) {
          const aspectRatio = canvas.width / canvas.height;
          const newSize = calculateBookSize(aspectRatio); // Set the book size based on aspect ratio
          setBookSize(newSize);
        }
      }

      // Set the generated thumbnails in the state
      setThumbnails(thumbnailList);
    } catch (error) {
      console.error("Error loading PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const username = getCookie("username");
  const navigate = useNavigate();
  return (
    <>
      <ToastContainer />

      <div className="view-container" style={{ overflow: "hidden" }}>
        {showTableOfContents && (
          <div className="thumbnails-sidebar">
            {thumbnails.map((thumbnailObj, index) => (
              <div key={index} className="thumbnail-container">
                <img
                  src={thumbnailObj.thumbnail} // Access the 'thumbnail' property here
                  alt={`Page ${thumbnailObj.pageNum}`}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(index)}
                />
                <div className="page-number">{thumbnailObj.pageNum}</div>
              </div>
            ))}
          </div>
        )}
        {authToken ? (
          <Link to="/collection">
            <div className="p-2 d-flex justify-content-end">
              {process.env.REACT_APP_TITLE === "1" ? (
                <button
                  type="button"
                  className="btn btn-danger btnsize d-flex align-items-center"
                >
                  <FaRegFolder className="btnlogo" />
                  <span className="btntext">Collection</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn gradient-button btnsizeaxiom btnsize d-flex align-items-center"
                >
                  <FaRegFolder className="btnlogo" />
                  <span className="btntext">Collection</span>
                </button>
              )}
            </div>
          </Link>
        ) : (
          <div
            className="p-2 d-flex justify-content-end"
            onClick={() => navigate(-1)}
          >
            <button type="button" class="btn btn-outline-secondary">
              <FaArrowLeftLong />
              <span className="px-2">Back</span>
            </button>
          </div>
        )}

        {loading && thumbnails.length <= 0 ? (
          <div className="d-flex justify-content-center align-items-center viewLoading">
            <div className="uploadloader">
              <Oval
                visible={true}
                height="100"
                width="100"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <div className="py-3">Loading....</div>
            </div>
          </div>
        ) : (
          <div className="clicktoflipbook">
            <div
              className="flipbook-wrapper1"
              style={{ transform: `scale(${zoomLevel})`, position: "relative" }}
            >
              <HTMLFlipBook
                width={bookSize.width}
                height={bookSize.height}
                ref={bookRef}
                onFlip={handleFlip}
                mobileScrollSupport={true}
                showCover={true}
                autoSize={true}
              >
                {thumbnails.map((thumbnailObj, index) => (
                  <div key={index} className="demoPage">
                    {thumbnailObj.thumbnail ? (
                      <img
                        src={thumbnailObj.thumbnail}
                        alt={`PDF Thumbnail ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <div className="thumbnail-placeholder">
                        Page {thumbnailObj.pageNum}
                      </div>
                    )}
                  </div>
                ))}
              </HTMLFlipBook>
            </div>

            <div
              className={
                process.env.REACT_APP_TITLE === "1"
                  ? "toolbox-viewflipbook d-flex justify-content-center"
                  : "toolbox-viewflipbook-axiom d-flex justify-content-center"
              }
            >
              <div className="tool text-center" onClick={handleFirstPage}>
                <FaAngleDoubleLeft />
              </div>
              <div
                className="tool text-center"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </div>
              <div
                className="tool text-center"
                onClick={handleNextPage}
                disabled={currentPage === numPages}
              >
                <FaChevronRight />
              </div>
              <div className="tool text-center" onClick={handleLastPage}>
                <FaAngleDoubleRight />
              </div>
              <div className="tool text-center" onClick={toggleTableOfContents}>
                {showTableOfContents ? <FaTh /> : <FaList />}
              </div>
              <div className="tool text-center" onClick={zoomIn}>
                <FaSearchPlus />
              </div>
              <div className="tool text-center" onClick={zoomOut}>
                <FaSearchMinus />
              </div>
              <div className="tool text-center" onClick={togglePlayPause}>
                {isPlaying ? <FaPause /> : <FaPlay />}
              </div>
              <div className="tool text-center" onClick={toggleMute}>
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
              </div>
              <div className="tool text-center" onClick={toggleFullScreen}>
                {isFullScreen ? <FaCompress /> : <FaExpand />}
              </div>
              <div className="tool text-center" onClick={downloadClick}>
                <FaDownload />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PDFFlipBook;
