import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getCookie } from "./Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { VscBook } from "react-icons/vsc";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCompress,
  FaExpand,
} from "react-icons/fa6";

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaDownload,
  FaList,
  FaPause,
  FaPlay,
  FaSearchMinus,
  FaSearchPlus,
  FaTh,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";

import { Document, Page, pdfjs } from "react-pdf";
import PageFlip from "react-pageflip";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { useParams } from "react-router-dom";
import flipSound from "../Sound/page-flip.ogg";
import eyeoff from "../Images/icons/eyeoff.svg";
import eyeon from "../Images/icons/eyeon.svg";
import { PDFDocument, rgb } from "pdf-lib";
import { getDocument } from "pdfjs-dist";
import HTMLFlipBook from "react-pageflip";
import { Oval } from "react-loader-spinner";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@latest/es5/build/pdf.worker.min.js";
const visibility = [
  { value: "0", label: "Public", icon: eyeon },
  { value: "1", label: "Private", icon: eyeoff },
];

const visibility1 = [
  { value: "2", label: "Sports" },
  { value: "3", label: "News" },
  { value: "4", label: "Entertainment" },
  { value: "5", label: "Articles" },
  { value: "6", label: "Others" },
];
const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);

const Option1 = (props) => (
  <components.Option {...props} className="country-option">
    {props.data.label}
  </components.Option>
);

function Flipbook({ sendFileData }) {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [pages, setPages] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [data, setData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playInterval, setPlayInterval] = useState(null);
  const [apiFileName, setAPIFileName] = useState(null);
  const pageFlipRef = useRef(null);
  const [show, setShow] = useState(false);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [safeMode, setSafeMode] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [categoriesData, setCategoriesData] = useState(false);
  const [audio] = useState(new Audio(flipSound));
  const { id } = useParams();
  const flipIntervalRef = useRef(null);
  const bookRef = useRef(null);

  const [selectedCategories, setSelectedCategories] = useState("");
  const [safeModeStatus, setSafeModeStatus] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState(visibility[0]);
  const [visibilityStatus, setVisibilityStatus] = useState(1);

  useEffect(() => {
    fetchRecentData();
  }, []);

  const fetchRecentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-publication?flipbook_id=${id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // setFlipBookData(responseData?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handlePrevPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipPrev();
    }
  };

  const handleNextPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().flipNext();
    }
  };

  const handleFirstPage = () => {
    if (bookRef.current) {
      bookRef.current.pageFlip().turnToPage(0);
    }
  };

  const handleLastPage = () => {
    if (bookRef.current) {
      const totalPages = bookRef.current.pageFlip().getPageCount();
      bookRef.current.pageFlip().turnToPage(totalPages - 1);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        bookRef.current.pageFlip().flipNext();
      }, 1500); // Change the interval time as needed
      setPlayInterval(intervalId);
    } else {
      clearInterval(playInterval);
    }

    return () => clearInterval(playInterval);
  }, [isPlaying]);

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);

  const handleThumbnailClick = (index) => {
    bookRef.current.pageFlip().turnToPage(index);
  };

  const downloadClick = async () => {
    console.log("PDF URL:", pdfUrl);

    const watermarkText =
      process.env.REACT_APP_TITLE === "1"
        ? "Powered By SiloCloud"
        : "Powered By AxiomWebControl";
    const projectLink =
      process.env.REACT_APP_TITLE === "1"
        ? "https://publisher.silocloud.io"
        : "https://publisher.axiomwebcontrol.com/";

    const loadingToastId = toast.loading("Preparing your download...");

    try {
      // Fetch the existing PDF
      const existingPdfBytes = await fetch(pdfUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch PDF");
        return res.arrayBuffer();
      });

      // Load the existing PDF document
      const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
      const numPages = existingPdfDoc.getPageCount(); // Get number of pages

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Copy pages from the existing PDF and apply the watermark
      for (let i = 0; i < numPages; i++) {
        const [page] = await pdfDoc.copyPages(existingPdfDoc, [i]);
        const { width, height } = page.getSize();

        // Add watermark text in faint red
        page.drawText(watermarkText, {
          x: 15,
          y: 20,
          size: 14,
          color: rgb(1, 0, 0), // Faint red
          opacity: 0.3,
        });

        // Add project link in blue below the watermark text
        page.drawText(projectLink, {
          x: 15,
          y: 8,
          size: 10,
          color: rgb(0, 0, 1), // Blue
          opacity: 0.8,
        });

        // Add the modified page to the new PDF document
        pdfDoc.addPage(page);
      }

      // Save the modified PDF
      const pdfBytes = await pdfDoc.save();

      // Trigger download
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `silo-${apiFileName}`;
      link.click();

      // Dismiss the loading toast and show success message
      toast.dismiss(loadingToastId);
      toast.success("Download complete!");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.dismiss(loadingToastId);
      toast.error("Failed to download PDF.");
    }
  };

  useEffect(() => {
    fetchDatafolder();
    fetchApiPdf();
  }, []);

  useEffect(() => {
    const loadPages = async () => {
      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        const canvas = await document.querySelector(
          `.demoPage canvas:nth-child(${i})`
        );
        pagesArray.push(canvas?.toDataURL("image/jpeg"));
      }
      setPages(pagesArray);
    };
    if (numPages) loadPages();
  }, [numPages]);

  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApiPdf = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-tempurl?pdf_id=${id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      // const newdata = await responseData.data.pdf_list[0].pdf_file;
      const newdata = await responseData.data.pdf_file_url;
      const newFile = await responseData.data.file_name;
      setpdfUrl(newdata);
      setAPIFileName(newFile);
    } catch (error) {
      console.error(error);
    }
  };

  // get-categories API
  const FetchCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publish/categories`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log(responseData);
      setCategoriesData(responseData?.data?.categories);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    FetchCategories();
  }, []);

  const [bookSize, setBookSize] = useState({ width: 400, height: 500 });
  const calculateBookSize = (aspectRatio) => {
    return aspectRatio > 1
      ? { width: 420, height: 320 }
      : { width: 400, height: 500 };
  };
  useEffect(() => {
    if (pdfUrl) {
      console.log("Fetching PDF from URL:", pdfUrl);
      setLoading(true);
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch PDF");
          }
          return response.arrayBuffer();
        })
        .then(async (pdfData) => {
          const pdf = await getDocument({ data: pdfData }).promise;
          const totalPages = pdf.numPages;
          const thumbnailPromises = [];

          for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            if (pageNum === 1) {
              const { width, height } = page.getViewport({ scale: 0.5 });
              const aspectRatio = width / height;
              const newSize = calculateBookSize(aspectRatio);
              setBookSize(newSize);
            }

            thumbnailPromises.push(generateThumbnail(pdf, pageNum));
          }

          const generatedThumbnails = await Promise.all(thumbnailPromises);
          setThumbnails(generatedThumbnails);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
          setLoading(false);
        });
    }
  }, [pdfUrl]);

  const generateThumbnail = async (pdf, pageNum) => {
    try {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      const base64Data = canvas.toDataURL("image/png");
      console.log(`Generated thumbnail for page ${pageNum}`);
      return { pageNum, thumbnail: base64Data };
    } catch (error) {
      console.error(`Error generating thumbnail for page ${pageNum}:`, error);
      return { pageNum, thumbnail: null };
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const pdfData = useSelector((state) => state.pdfUrl.pdfUrl);

  useEffect(() => {
    const handleFlip = () => {
      if (audioRef.current && !isMuted) {
        audioRef.current.play();
      }
    };

    if (bookRef.current) {
      bookRef.current.pageFlip().on("flip", handleFlip);
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [isMuted]);

  useEffect(() => {
    const data = pdfData.dataUri;

    setFileData(data);

    if (data && data?.startsWith("data:application/pdf")) {
      const base64 = data.split(",")[1]; // Remove the prefix
      const pdfData = atob(base64);
      const loadingTask = pdfjsLib.getDocument({ data: pdfData });
      loadingTask.promise
        .then((pdf) => {
          const numPages = pdf.numPages;
          const loadedPages = [];
          const renderPage = (pageNum) => {
            pdf.getPage(pageNum).then((page) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext).promise.then(() => {
                loadedPages.push(canvas?.toDataURL());
                if (loadedPages.length === numPages) {
                  setPages(loadedPages);
                  setIsLoading(false); //shimmer before display the flipbook
                } else {
                  renderPage(pageNum + 1);
                }
              });
            });
          };
          renderPage(1);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    } else {
      console.error("Invalid or missing PDF data");
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [navigate]);
  const [description, setDescription] = useState("");
  // Upload Publisher -API
  const publishClick = async () => {
    const toastId = toast.loading("Publishing...");

    try {
      const base64 = fileData.split(",")[1];
      const formData = new FormData();
      formData.append("title", input);
      formData.append("description", description);
      formData.append("flipbook_id", id);
      formData.append("visibility", visibilityStatus);
      formData.append("safe_mode", safeModeStatus);
      formData.append("categories", selectedCategories);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publish`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const pdfs = responseData?.data?.fileData || [];
      setData(pdfs);

      toast.update(toastId, {
        render: "Publication successful!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.update(toastId, {
        render: "Publication failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      console.error("Error converting base64 to Blob:", error);
      return null;
    }
  }
  //To select visibility

  const handleChange = (value) => {
    setSelectedCountry(value);
    if (value?.label === "Public") {
      setVisibilityStatus(1);
    } else if (value?.label === "Private") {
      setVisibilityStatus(2);
    }
  };

  console.log(visibilityStatus, "visibilityStatus");

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={selectedCountry.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const [selectedCountry1, setSelectedCountry1] = useState(visibility[0]);

  const handleChange1 = (value) => {
    setSelectedCountry1(value);
  };

  const SingleValue1 = ({ children, ...props }) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        {
          <>
            <div className="book-container">
              <div className="book">
                <div className="page page-left">
                  <div className="shimmer"></div>
                </div>
                <div className="page page-right">
                  <div className="shimmer"></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    );
  }

  const toggleSafeMode = () => {
    setSafeMode((prevSafeMode) => {
      const newSafeMode = !prevSafeMode;
      setSafeModeStatus(newSafeMode ? 0 : 1);
      return newSafeMode;
    });
  };

  const handleCategoryChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions
        .map((option) => option.id)
        .join(", ");
      setSelectedCategories(selectedValues);
    }
  };
  console.log(thumbnails, "selectedCategories");
  console.log(loading, "safeModeStatus");

  return (
    <>
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row ">
          <div className="col-lg-9 col-md-8">
            {loading && thumbnails.length <= 0 ? (
              <div className="d-flex justify-content-center align-items-center viewLoading">
                <div className="uploadloader">
                  <Oval
                    visible={true}
                    height="100"
                    width="100"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  <div className="py-3">Loading....</div>
                </div>
              </div>
            ) : (
              <>
                <div className="Flipbook-container">
                  {showTableOfContents && (
                    <div className="thumbnails-sidebar-publish">
                      {thumbnails.map((thumbnailObj, index) => (
                        <div
                          key={index}
                          className="thumbnail-container-publish"
                        >
                          <img
                            src={thumbnailObj.thumbnail} // Access the 'thumbnail' property here
                            alt={`Page ${thumbnailObj.pageNum}`}
                            className="thumbnail-publish"
                            onClick={() => handleThumbnailClick(index)}
                          />
                          <div className="page-number-publish">
                            {thumbnailObj.pageNum}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    className="flipbook-wrapper2"
                    style={{
                      transform: `scale(${zoomLevel})`,
                      position: "relative",
                    }}
                  >
                    <HTMLFlipBook
                      width={bookSize.width}
                      height={bookSize.height}
                      ref={bookRef}
                      onFlip={handleFlip}
                      mobileScrollSupport={true}
                      showCover={true}
                      autoSize={true}
                    >
                      {thumbnails.map((thumbnailObj, index) => (
                        <div key={index} className="demoPage">
                          {thumbnailObj.thumbnail ? (
                            <img
                              src={thumbnailObj.thumbnail}
                              alt={`PDF Thumbnail ${index + 1}`}
                              style={{
                                width: "100%",
                                height: "auto",
                              }}
                            />
                          ) : (
                            <div className="thumbnail-placeholder">
                              Page {thumbnailObj.pageNum}
                            </div>
                          )}
                        </div>
                      ))}
                    </HTMLFlipBook>
                  </div>
                </div>

                <div
                  className={
                    process.env.REACT_APP_TITLE === "1"
                      ? "toolbox-publish d-flex justify-content-center"
                      : "toolbox-publish-axiom d-flex justify-content-center"
                  }
                >
                  <div className="tool text-center" onClick={handleFirstPage}>
                    <FaAngleDoubleLeft />
                  </div>
                  <div
                    className="tool text-center"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                  </div>
                  <div
                    className="tool text-center"
                    onClick={handleNextPage}
                    disabled={currentPage === numPages}
                  >
                    <FaChevronRight />
                  </div>
                  <div className="tool text-center" onClick={handleLastPage}>
                    <FaAngleDoubleRight />
                  </div>
                  <div
                    className="tool text-center"
                    onClick={toggleTableOfContents}
                  >
                    {showTableOfContents ? <FaTh /> : <FaList />}
                  </div>
                  <div className="tool text-center" onClick={zoomIn}>
                    <FaSearchPlus />
                  </div>
                  <div className="tool text-center" onClick={zoomOut}>
                    <FaSearchMinus />
                  </div>
                  <div className="tool text-center" onClick={togglePlayPause}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                  </div>
                  <div className="tool text-center" onClick={toggleMute}>
                    {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                  </div>
                  <div className="tool text-center" onClick={toggleFullScreen}>
                    {isFullScreen ? <FaCompress /> : <FaExpand />}
                  </div>
                  <div className="tool text-center" onClick={downloadClick}>
                    <FaDownload />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="col-lg-3 col-md-4 bg-white publishFlipbookSiderbar border-start"
            style={{
              height:
                process.env.REACT_APP_TITLE === "1"
                  ? "calc(100vh - 118px)"
                  : "calc(100vh - 55px)",
            }}
          >
            <div className="container mt-4">
              <div className="form-group">
                <label htmlFor="title" className="title">
                  Title*
                </label>
                <input
                  disabled={thumbnails.length <= 0}
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Enter a title before publishing"
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description" className="description">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter a description for your publication"
                ></textarea>
              </div>
              <div className="p-1 form-group">
                <label htmlFor="description" className="title">
                  Availibility
                </label>
                <Select
                  value={selectedCountry}
                  options={visibility}
                  onChange={handleChange}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </div>
              <div className="p-1 form-group">
                <label htmlFor="description" className="title">
                  Category
                </label>
                <Select
                  // defaultValue={""}
                  isMulti
                  name="colors"
                  options={categoriesData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleCategoryChange}
                />
              </div>
              <div className="safe-mode-toggle py-1">
                <span className="px-2">Safe mode</span>
                <div
                  className={`toggle-switch ${safeMode ? "active" : ""}`}
                  onClick={toggleSafeMode}
                >
                  <div className="toggle-circle"></div>
                </div>
              </div>
              <div className="my-4">
                <button
                  className="publish-button"
                  onClick={publishClick}
                  disabled={!input} // Disable if no input
                  style={{
                    opacity: input ? 1 : 0.5, // Lower opacity when disabled
                    cursor: input ? "pointer" : "not-allowed", // Change cursor when disabled
                  }}
                >
                  <VscBook className="fs-3" />{" "}
                  <span className="px-2">Publish </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Flipbook;
