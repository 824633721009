import { Link } from "react-router-dom";
import flipbook from "../Images/Silo_Home.png";
import flipbook1 from "../Images/Axiom_Home.png";
import { LuFolderClosed } from "react-icons/lu";
import { FaPlus, FaRegFolder } from "react-icons/fa6";
import React, { useState, useEffect } from "react";
import { getCookie } from "./Utils";
import { ShimmerTable } from "react-shimmer-effects";
import Cookies from "js-cookie";
export default function First() {
  const [folderData, setFolderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleFolderClick = (index) => {
    setActiveIndex(index);
  };

  // Collection and listing - GET API
  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setFolderData(responseData?.data?.collections);
      setIsLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    fetchDatafolder();
  }, []);

  let authToken = Cookies.get("authToken");

  return (
    <div className="container-fluid p-0">
      <div className="heightvh home">
        <div className="p-3 d-flex justify-content-end">
        {process.env.REACT_APP_TITLE === "1" ? (
          <Link to="/collection">
            <button
              type="button"
              className="btn btn-danger btnsize d-flex align-items-center"
            >
              <FaRegFolder className="btnlogo" />
              <span className="btntext">Collection</span>
            </button>
          </Link>
        ):( 
          <Link to="/collection">
            <button
              type="button"
              className="btn gradient-button btnsize d-flex align-items-center"
            >
              <FaRegFolder className="btnlogo" />
              <span className="btntext">Collection</span>
            </button>
          </Link>
          )}
        </div>
        <div className="p-4 text-center container-home">
          <div className="d-flex justify-content-center py-2">
            {process.env.REACT_APP_TITLE === "1" ? (
              <img className="homeflipbookimg" src={flipbook} alt="Flipbook" />
            ) : (
              <img className="homeflipbookimg" src={flipbook1} alt="Flipbook" />
            )}
          </div>
          <div className="d-flex justify-content-center">
            <p className="heading">Let's start to create your flipbook</p>
          </div>
          {process.env.REACT_APP_TITLE === "1" ? (
            <div className="d-flex justify-content-center">
              <Link to="/create">
                <button
                  type="button"
                  className="btn btn-danger btnsize d-flex align-items-center"
                >
                  <FaPlus className="btnlogo" />
                  <span className="btntext">Create</span>
                </button>
              </Link>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Link to="/create">
                <button
                  type="button"
                  className="btn gradient-button btnsizeaxiom d-flex align-items-center"
                >
                  <FaPlus className="btnlogo" />
                  <span className="btntext">Create</span>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
