import React, { useState } from "react";
import SellSettings from "./SellSettings";
import PreviewPages from "./PreviewPages";
import FreeAccesscode from "./FreeAccesscode";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPdfById, sellFlipBook } from "./api/api";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "./Utils";
import { LuShoppingCart } from "react-icons/lu";
import { toast, ToastContainer } from "react-toastify";

// import { SlBookOpen } from "react-icons/sl";

export default function Sell() {
  const [toggle, setToggle] = useState(1);

  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const { pdf_id } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["sell", pdf_id],
    queryFn: () => fetchPdfById(pdf_id),
    staleTime: Infinity,
    gcTime: 0,
    enabled: true,
  });

  const previewthumbnail = data?.data?.pdf_list[0]?.thumbnail || "";
  const pdfUrl = data?.data?.pdf_list[0]?.pdf_file || "";
  const navigate = useNavigate();
  const sellFlipBookMutation = useMutation({
    mutationFn: sellFlipBook,
    onSuccess: (data) => {
      navigate("/browse");
    },
    onError: (error) => {
      console.error("Sell Failed:", error.message);
    },
  });

  const handleSellNow = () => {
    toast.promise(sellFlipBookMutation.mutateAsync(pdf_id), {
      pending: "Processing your flipbook...",
      success: "Flipbook added to store! 🎉",
      error: {
        render({ data }) {
          return `Seomething went wrong`;
        },
      },
    });
  };
  
  return (
    <>
      <ToastContainer />
      <div className="container-fluid sell-container">
        <div className="d-flex justify-content-end py-2"></div>
        <div className="bg-white sell-navtab my-3 d-flex justify-content-between">
          <Nav tabs className="p-4 nav-tabs">
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "1" })}
                onClick={(ev) => {
                  setToggle(1);
                  ev.preventDefault();
                  toggleIconTab("1");
                }}
              >
                <span className="navlinktext">Settings</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "2" })}
                onClick={(ev) => {
                  setToggle(2);
                  ev.preventDefault();
                  toggleIconTab("2");
                }}
              >
                <span className="navlinktext">Preview Pages</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "3" })}
                onClick={(ev) => {
                  setToggle(3);
                  ev.preventDefault();
                  toggleIconTab("3");
                }}
              >
                <span className="navlinktext">Free Access Code</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="container-fluid sell-tab bg-white">
          <div className="row">
            <div className="col-md-9">
              {toggle === 1 ? (
                <SellSettings />
              ) : toggle === 2 ? (
                <PreviewPages pdfurl={pdfUrl} />
              ) : (
                <FreeAccesscode />
              )}
            </div>
            <div className="col-md-3">
              <div className="previewimg d-flex flex-column">
                <img
                  className="h-100"
                  src={previewthumbnail}
                  alt=""
                  style={{ height: "250px" }}
                />
                <span class="published-text mt-1">
                  by{" "}
                  <a class="author-link">
                    {getCookie("username") || "ThePika"}
                  </a>
                </span>
                <div className="d-flex justify-content-center mt-2">
                  {process.env.REACT_APP_TITLE ===
                  "1" ? (
                    <button
                      type="button"
                      className="btn btn-danger d-flex align-items-center btn-block justify-content-center"
                      onClick={handleSellNow}
                      disabled={toggle === 2 ? "" : true}
                    >
                      <LuShoppingCart className="btnlogo" />
                      <span className="text-light btntext ms-2">Sell Now!</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn gradient-button d-flex align-items-center btn-block justify-content-center"
                      onClick={handleSellNow}
                      disabled={toggle === 2 ? "" : true}
                    >
                      <LuShoppingCart className="btnlogo" />
                      <span className="text-light btntext ms-2">Sell Now!</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
