import React from "react";

export default function SellSettings() {
  return (
    <div
      class="container-fluid sell-settings py-4 sellpx bg-white bgwheight"
      style={{
        height:
          process.env.REACT_APP_TITLE === "1"
            ? " calc(100vh - 270px)"
            : "calc(100vh - 207px)",
      }}
    >
      <h1 className="selltitle">Sell Settings</h1>
      <div class="form-check mb-3 py-2">
        <input
          type="checkbox"
          class="form-check-input"
          id="makeAvailableForPurchase"
        />
        <label
          class="form-check-label fw-500 selltitle"
          for="makeAvailableForPurchase"
        >
          Make available for purchase
        </label>
        <small class="form-text text-muted selltitle">
          Let your readers buy your publication through.
        </small>
      </div>
      <div class="container price-settings py-4">
        <h2 className="selltitle">Price settings</h2>
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="unitPrice" class="form-label selltitle">
              Unit Price
            </label>
            <input
              type="number"
              class="form-control"
              id="unitPrice"
              value="0.50"
            />
          </div>
          <div class="col-md-6">
            <label for="defaultCurrency" class="form-label">
              Default Currency
            </label>
            <select class="form-select" id="defaultCurrency">
              <option value="USD">USD - US Dollar</option>
              {/* <option value="EUR">EUR - Euro</option> */}
            </select>
          </div>
        </div>
        <small class="form-text text-muted mb-3 selltitle">
          Minimum 0.50 EUR. Issuu commission fee of 0% and payment provider fee
          apply.
        </small>
      </div>
      <div className="hr"> </div>
    </div>
  );
}
