import React, { useState, useEffect } from "react";
import { getCookie } from "./Utils";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function Publications({ count, setCount, publicationdata }) {
  const navigate = useNavigate();

  const navigateToPublish = (id) => {
    navigate(`/publishFlipbook/view/${id}`);
  };

  const [isLoading, setIsLoading] = useState();

  const SkeletonPosts = () => {
    const skeletonArray = Array(6).fill(0);

    return (
      <div className="row">
        {skeletonArray.map((_, index) => (
          <div key={index} className="col-md-2 col-sm-4 col-xs-6 mb-2">
            <Skeleton className="postimage" />
            <div className="postText">
              <Skeleton />
              <Skeleton className="published-text" />
              <Skeleton className="published-text" />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid profile-container">
        {isLoading ? (
          <SkeletonPosts />
        ) : (
          <div className="row">
            {publicationdata
              ? publicationdata?.publications.map((item, idx) => (
                  <div className="col-lg-2 col-sm-4">
                    <div
                      className="card w-100"
                      onClick={() =>
                        navigateToPublish(item?.flipbook?.flipbook_id)
                      }
                    >
                      <img
                        src={item?.flipbook?.thumbnail}
                        class="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="publicationstext pt-2">{item?.title}</div>
                    <div className="publicationsdate">
                      Published at {item.published_date}
                    </div>
                  </div>
                ))
              : ""}
          </div>
        )}
      </div>
    </>
  );
}
