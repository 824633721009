import React, { useEffect, useState } from "react";
import {
  Col,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Alert,
} from "reactstrap";
import { GoCopy, GoSync, GoCheck, GoPerson } from "react-icons/go";
import { CiDiscount1 } from "react-icons/ci";
import CommonTooltip from "./CommonTooltip.jsx";
import { getCookie } from "./Utils.js";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
export default function FreeAccesscode() {
  const [accessCode, setAccessCode] = useState(generateAccessCode());
  const [copyStatus, setCopyStatus] = useState("Copy Access Code");
  const [copied, setCopied] = useState(false);
  function generateAccessCode() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
    return code;
  }

  const handleRecreate = () => {
    const newCode = generateAccessCode();
    setAccessCode(newCode);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(accessCode);
    setCopied(true);
    setCopyStatus("Copied!");
    setTimeout(() => {
      setCopied(false);
      setCopyStatus("Copy Access Code");
    }, 2000);
  };
  const [inputValue, setInputValue] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const debouncedShowAlert = debounce(() => {
    toast.error("Please enter a value between 1 and 50");
  }, 300);

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (value === "") {
      setInputValue(value);
      setIsInvalid(false);
      return;
    }

    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 50) {
      setInputValue(parsedValue);
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
      debouncedShowAlert();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    return () => {
      debouncedShowAlert.cancel();
    };
  }, [debouncedShowAlert]);
  return (
    <div
      className="container-fluid py-4 bg-white sellpx bgwheight"
      style={{
        height:
          process.env.REACT_APP_TITLE === "1"
            ? " calc(100vh - 270px)"
            : "calc(100vh - 207px)",
      }}
    >
      <div className="selltitle">
        Your publication must be published as for sale before you can create
        free access codes.
      </div>

      <Row className="mt-1">
        <Col md="6">
          <InputGroup className="mb-0 mt-1">
            <Input
              value={accessCode}
              readOnly
              style={{ height: "auto" }}
              placeholder="Free access code"
            />

            <InputGroupText
              style={{ cursor: "pointer" }}
              id="recreate"
              onClick={handleRecreate}
            >
              <GoSync />
            </InputGroupText>
            <CommonTooltip
              target="recreate"
              content="Generate New Access Code"
              placement="bottom"
            />

            <InputGroupText
              style={{ cursor: "pointer" }}
              id="copyButton"
              onClick={handleCopy}
            >
              {copied ? <GoCheck className="text-success" /> : <GoCopy />}
            </InputGroupText>
            <CommonTooltip
              target="copyButton"
              content={copyStatus}
              placement="right"
            />
          </InputGroup>
          <FormText className="selltitle">Create free access code</FormText>
        </Col>
        <Col md="6">
          <InputGroup className="mb-0 mt-1">
            <Input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              style={{ height: "auto" }}
              placeholder="Choose how many users can apply for free access"
              invalid={isInvalid}
            />
            <InputGroupText id="userchoose">
              <GoPerson />
            </InputGroupText>
            <CommonTooltip
              target="userchoose"
              content="Choose how many users can apply for free access"
              placement="right"
            />
          </InputGroup>
          <FormText className="selltitle">Max 50 Users</FormText>
        </Col>
      </Row>
      <Alert color="warning" className="mt-2 alertbg">
        <h4 className="alert-heading selltitle">
          <CiDiscount1 /> Free Access Codes!
        </h4>
        <p className="selltitle">
          These are your free access codes. If a user applies one of these codes
          while purchasing your flipbook, they will be able to get it for free.
        </p>
        <hr className="border border-1 border-warning" />
        <p className="mb-0 selltitle">
          You can share these codes with your audience to offer them free access
          to your flipbook.
        </p>
      </Alert>
    </div>
  );
}
