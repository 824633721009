import React from "react";
import { ListGroup, ListGroupItem, Spinner } from "reactstrap";

const NotificationSkeletonLoader = () => {
  return (
    <ListGroup>
      {Array.from({ length: 10 }).map((_, index) => {
        return (
          <ListGroupItem
            key={index}
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex align-items-center gap-2">
              <div>
                <Spinner
                  type="grow"
                  color="secondary"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                />
              </div>
              <div className="nk-notification-content">
                <div
                  className="skeleton-text"
                  style={{
                    width: "10rem",
                    height: "1.25rem",
                    backgroundColor: "#e0e0e0",
                    marginBottom: "0.5rem",
                  }}
                />
                <div
                  className="skeleton-text"
                  style={{
                    width: "4rem",
                    height: "1rem",
                    backgroundColor: "#e0e0e0",
                  }}
                />
              </div>
            </div>
            <div>
              <div
                className="skeleton-text"
                style={{
                  width: "1.5rem",
                  height: "1rem",
                  backgroundColor: "#e0e0e0",
                }}
              />
            </div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default NotificationSkeletonLoader;
