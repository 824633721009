import React, { useState } from "react";
import { Tooltip } from "reactstrap";

export default function CommonTooltip({
  target,
  content,
  placement = "bottom",
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={placement}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggleTooltip}
    >
      {content}
    </Tooltip>
  );
}
