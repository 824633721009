import React, { useState, useEffect } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { jsPDF } from "jspdf";
import { ShimmerThumbnail } from "react-shimmer-effects";
import FancyBox from "./FancyBox";

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

const ThumbnailURL = ({ pdfUrl, onSelectedPagesChange, flipbook_id }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [maxLimitReached, setMaxLimitReached] = useState(false);

  const maxPercentage = 25;

  useEffect(() => {
    if (pdfUrl) {
      const cachedData = JSON.parse(localStorage.getItem(flipbook_id));
      const existingKeys = Object.keys(localStorage).filter((key) =>
        key?.startsWith("publisher_thumbnail_")
      );
      existingKeys.forEach((key) => {
        if (key !== flipbook_id) {
          localStorage.removeItem(key);
        }
      });

      if (cachedData && cachedData.thumbnails && cachedData.totalPages) {
        setThumbnails(cachedData.thumbnails);
        setTotalPages(cachedData.totalPages);
        setLoading(false);
      } else {
        fetch(pdfUrl)
          .then((response) => response.arrayBuffer())
          .then(async (pdfData) => {
            const typedArray = new Uint8Array(pdfData);
            const pdf = await getDocument(typedArray).promise;
            const totalPages = pdf.numPages;
            setTotalPages(totalPages);

            const thumbnailArray = Array.from(
              { length: totalPages },
              (_, i) => ({
                pageNum: i + 1,
                thumbnail: null,
              })
            );

            setThumbnails(thumbnailArray);
            const generatedThumbnails = await generateThumbnailsInParallel(
              pdf,
              totalPages
            );
            setThumbnails(generatedThumbnails);

            cacheThumbnails(flipbook_id, generatedThumbnails, totalPages);

            setLoading(false);
          })
          .catch((error) => {
            console.error("Error loading PDF from URL: ", error);
            setLoading(false);
          });
      }
    }
  }, [pdfUrl, flipbook_id]);

  const cacheThumbnails = (flipbook_id, thumbnails, totalPages) => {
    const dataToCache = {
      thumbnails,
      totalPages,
    };
    localStorage.setItem(flipbook_id, JSON.stringify(dataToCache));
  };

  const generateThumbnailsInParallel = async (pdf, totalPages) => {
    const promises = [];

    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      promises.push(generateThumbnailForPage(pdf, pageNum));
    }

    return await Promise.all(promises);
  };

  const generateThumbnailForPage = async (pdf, pageNum) => {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    const base64Data = canvas.toDataURL("image/jpeg");

    return { pageNum, thumbnail: base64Data };
  };

  const handleCheckboxChange = (pageNum) => {
    const updatedSelectedPages = selectedPages.includes(pageNum)
      ? selectedPages.filter((p) => p !== pageNum)
      : [...selectedPages, pageNum];

    const newSelectedPages = updatedSelectedPages.includes(1)
      ? updatedSelectedPages
      : [1, ...updatedSelectedPages]; // Always keep page 1 selected

    setSelectedPages(newSelectedPages);
    onSelectedPagesChange(newSelectedPages);

    // Check if the 25% limit is reached and set state accordingly
    const selectedPercentage = (newSelectedPages.length / totalPages) * 100;
    if (selectedPercentage >= maxPercentage) {
      setMaxLimitReached(true); // Disable more selections
    } else {
      setMaxLimitReached(false); // Allow selections
    }
  };

  const createPdfWithSelectedPages = async () => {
    const pdf = new jsPDF();

    for (let i = 0; i < selectedPages.length; i++) {
      const pageNum = selectedPages[i];
      const thumbnailData = thumbnails.find(
        (t) => t.pageNum === pageNum
      )?.thumbnail;

      const img = new Image();
      img.src = thumbnailData;

      await new Promise((resolve) => {
        img.onload = () => {
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          pdf.addImage(img, "JPEG", 0, 0, width, height);

          if (i < selectedPages.length - 1) pdf.addPage();
          resolve();
        };
      });
    }

    pdf.addPage();
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    let text = "To read this book further, purchase it at:";
    let textWidth = pdf.getTextWidth(text);
    let lineHeight = 20;
    pdf.text(text, (pageWidth - textWidth) / 2, pageHeight / 2 - lineHeight);

    text = "https://publisher.silocloud.io";
    pdf.setTextColor(0, 0, 255);
    textWidth = pdf.getTextWidth(text);
    pdf.text(text, (pageWidth - textWidth) / 2, pageHeight / 2);

    text = "Thank you for supporting";
    pdf.setTextColor(255, 100, 0);
    textWidth = pdf.getTextWidth(text);
    pdf.text(text, (pageWidth - textWidth) / 2, pageHeight / 2 + lineHeight);

    pdf.save("Selected_Pages.pdf");
  };

  // Calculate selected percentage
  const selectedPercentage = (selectedPages.length / totalPages) * 100;

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <p className="selltitle">
          Selected <strong>{Math.floor(selectedPercentage, 100)}%</strong> of
          the book.
        </p>
        <button
          onClick={createPdfWithSelectedPages}
          style={{
            padding: "10px",
            cursor: "pointer",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Download Selected Pages PDF
        </button>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {[...Array(6)].map((_, idx) => (
            <div key={idx} style={{ margin: "10px" }}>
              <ShimmerThumbnail height={200} width={150} rounded />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <FancyBox
            options={{
              Carousel: {
                infinite: false,
              },
            }}
          >
            {thumbnails.map(({ pageNum, thumbnail }) => (
              <div
                key={pageNum}
                style={{
                  position: "relative",
                  margin: "10px",
                  border: "1px solid #ccc",
                  opacity:
                    !selectedPages.includes(pageNum) && maxLimitReached
                      ? 0.5
                      : 1, // Set opacity based on selection and limit
                }}
              >
                <input
                  type="checkbox"
                  className="form-check-input shadow border-1 border-primary"
                  style={{
                    position: "absolute",
                    top: "9px",
                    left: "30px",
                  }}
                  checked={selectedPages.includes(pageNum)}
                  onChange={() => handleCheckboxChange(pageNum)}
                  disabled={
                    !selectedPages.includes(pageNum) &&
                    maxLimitReached &&
                    pageNum !== 1
                  } // Disable if 25% reached and this is not page 1
                />
                {thumbnail ? (
                  <a data-fancybox="gallery" href={thumbnail}>
                    <img
                      src={thumbnail}
                      alt={`Thumbnail of page ${pageNum}`}
                      style={{
                        width: "150px",
                        // height: "200px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                ) : (
                  <ShimmerThumbnail height={200} width={150} rounded />
                )}
              </div>
            ))}
          </FancyBox>
        </div>
      )}
    </div>
  );
};

export default ThumbnailURL;
