import React from 'react'
import { AiOutlineDelete } from "react-icons/ai";
import { GoShareAndroid } from "react-icons/go";
import postimage from "../Images/social.png";

export default function SocialPost() {

    const posts = [
        { id: 1, title: "Untitled Social Post", date: "8/12/2024"},
        { id: 2, title: "Untitled Social Post", date: "7/31/2024"},
        { id: 3, title: "Untitled Social Post", date: "8/3/2024"}
    ];
  return (
    <>
    <div className='sp px-4 py-2'>Social posts</div>
    <div className="social-posts">
    <div className="post-card new-post">
        <div className="add-post">
            <span className="add-icon">+</span>
            <p className='nsp'>New Social Post</p>
        </div>
    </div>
    {posts.map(post => (
        <div className="post-card" key={post.id}>
            <img src={postimage} alt={post.title} className="post-image" />
            <div className="post-content">
                <p className="post-title m-0 py-1">{post.title}</p>
                <p className="post-date">Modified on {post.date}</p>
            </div>
            <div className="post-actions">
                <span className="action-icon share-icon"><GoShareAndroid/></span>
                <span className="action-icon delete-icon"><AiOutlineDelete/></span>
            </div>
        </div>
    ))}
</div>
</>
  )
}
