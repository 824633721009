import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { BsBook, BsCurrencyDollar, BsLink } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function SellPublishe() {
  const location = useLocation();
  const flipbook_id = parseInt(location.pathname.match(/\d+/)[0], 10);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-1 d-none d-md-block sidebarpublish border-end"
          style={{
            height:
              process.env.REACT_APP_TITLE === "1"
                ? "calc(100vh - 118px)"
                : "calc(100vh - 55px)",
                position: "fixed",
          
           
          }}
        >
          <Link to="/home">
            <div
              className={`sidebaritem1 py-3 ${
                location.pathname === "/home" ? "active" : ""
              }`}
            >
              <MdKeyboardBackspace className="sidebaricon1" />
              <span className="sidebartext1">Back</span>
            </div>
          </Link>

          <Link to={`publishFlipbook/${flipbook_id}`}>
            <div
              className={`sidebaritem1 py-3 ${
                location.pathname.includes("publishFlipbook") ? "active" : ""
              }`}
            >
              <BsBook className="sidebaricon1" />
              <span className="sidebartext1">Publication</span>
            </div>
          </Link>

          <Link to={`sell/${flipbook_id}`}>
            <div
              className={`sidebaritem1 py-3 ${
                location.pathname.includes("sell") ? "active" : ""
              }`}
            >
              <BsCurrencyDollar className="sidebaricon1" />
              <span className="sidebartext1">Selling</span>
            </div>
          </Link>

          <Link to="linking">
            <div
              className={`sidebaritem1 py-3 ${
                location.pathname.includes("linking") ? "active" : ""
              }`}
            >
              <BsLink className="sidebaricon1" />
              <span className="sidebartext1">Linking</span>
            </div>
          </Link>

          <Link to="media">
            <div
              className={`sidebaritem1 py-3 ${
                location.pathname.includes("media") ? "active" : ""
              }`}
            >
              <FaRegFileAlt className="sidebaricon1" />
              <span className="sidebartext1">Media</span>
            </div>
          </Link>
        </div>

        {/* Main content area */}
        <div className="col-md-11 offset-md-1 px-0" >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
