import React, { useState, useRef, useEffect } from "react";
import { pdfjs } from "react-pdf";
import pdf from "../Images/Silo_Dropzone.png";
import { useNavigate} from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { openDB, addItem } from "./IndexedDB";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

function Dropdown() {
  const [showUpload, setShowUpload] = useState(true);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const dropAreaRef = useRef(null);

  const handleFileUpload = async (file) => {
    const MAX_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

    if (file.size > MAX_SIZE) {
      toast.error("File size exceeds 5 MB. Please select a smaller file.");
      return;
    }

    if (file) {
      const db = await openDB();

      // Convert file to base64 with data URI scheme
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const dataUri = reader.result; // Get the data URI

          const fileId = await addItem(db, { file });
          localStorage.setItem("uploadedFileId", fileId);
          localStorage.setItem("uploadedFile", dataUri); // Store data URI

          navigate("/publishFlipbook");
        } catch (e) {
          toast.error("Error storing the file. Please try again.");
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }

    setFile(file);
    setShowUpload(false);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.add("drag-over");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  useEffect(() => {
    const dropArea = dropAreaRef.current;
    dropArea.addEventListener("dragover", handleDragOver);
    dropArea.addEventListener("dragleave", handleDragLeave);
    dropArea.addEventListener("drop", handleDrop);
    return () => {
      dropArea.removeEventListener("dragover", handleDragOver);
      dropArea.removeEventListener("dragleave", handleDragLeave);
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="my-5">
      <div
        ref={dropAreaRef}
        className="dropzone container p-3 my-4 drop-area w-50"
        onClick={triggerFileUpload}
        >
        <div className="d-flex justify-content-center">
          <img
            src={pdf}
            alt="Upload PDF"
            style={{ cursor: "pointer" }}
            className="py-2 img-fluid dropdownimg"
            />
          <input
            id="file-upload"
            type="file"
            accept="application/pdf"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
            ref={fileInputRef}
            />
        </div>
        <div className="customfont text-center fs-1">Drag & Drop</div>
        <div className="customfont text-center py-1">OR</div>
        <div className="d-flex justify-content-center py-1">
          <button
            type="button"
            className="btn btn-danger d-flex align-items-center"
            >
            <FiUpload className="btnlogo" />
            <span className="text-light btntext ms-2">Upload File</span>
          </button>
        </div>
      </div>
    </div>
    </>
  );
}
export default Dropdown;
