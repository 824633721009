import React, { useState, useEffect } from "react";
import publicationIcon from "../Images/PDFthumb-removebg-preview.png";
import { Label, Button } from "reactstrap";
import { VscBook } from "react-icons/vsc";
import { BsThreeDots } from "react-icons/bs";
import { LuView } from "react-icons/lu";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsCurrencyDollar } from "react-icons/bs";
import { AiOutlineDelete, AiOutlineShareAlt } from "react-icons/ai";
import { GoShareAndroid } from "react-icons/go";
import { getCookie } from "./Utils";
import NoResultsImage from "../Images/NoResultFound.png";
import {
  ShimmerCategoryItem,
  ShimmerContentBlock,
} from "react-shimmer-effects";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GrArticle, GrDocumentImage } from "react-icons/gr";
import { CiEdit } from "react-icons/ci";
import { SlEye } from "react-icons/sl";

export default function Publishe() {
  const [show, setShow] = useState(false);
  const [showPopover, setShowPopover] = useState(null);
  const handleToggle = () => setShow(!show);
  const [searchKeywords, setSearchKeyword] = useState("");
  const [del, setDel] = useState(null);
  const [data, setData] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [flipBookId, setFlipBookId] = useState(null);
  const navigate = useNavigate();

  const searchFlipBook = (event) => {
    setSearchKeyword(event.target.value);
  };

  useEffect(() => {
    fetchRecentData();
  }, [searchKeywords]);

  const fetchRecentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-publication`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Delete publication -API

  const deletePublication = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/delete-publication/${id}`,
        {
          method: "DELETE",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setDel(responseData?.data?.get_pdf);
      toast.success("Flipbook deleted successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleShare = (item) => {
    if (navigator.share) {
      navigator
        .share({
          title: item.pdf_file_name,
          text: "Check out this flipbook!",
          url: `${process.env.REACT_APP_PUBLIC_URL}publish/${item}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Share not supported on this browser");
    }
  };

  const navigateToPublish = (id) => {
    navigate(`/publish/publishFlipbook/${id}`);
  };

  const navigateToSell = (id) => {
    navigate(`/publish/sell/${id}`);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="d-flex flex-column">
          <div
            className="text-left p-2"
            onClick={() => navigateToSell(flipBookId)}
          >
            <BsCurrencyDollar className="fs-5 toggleicon" />{" "}
            <span className="toggle px-2"> Sell publication</span>
          </div>
          <div className="text-left p-2">
            <GrArticle className="fs-5 toggleicon" />{" "}
            <span className="toggle px-2">Create Articles</span>
          </div>
          <div className="text-left p-2">
            <GrDocumentImage className="fs-5 toggleicon" />{" "}
            <span className="toggle px-2">Create Social Post</span>
          </div>
          <div
            className="text-left p-2"
            onClick={() => deletePublication(showPopover)}
          >
            <AiOutlineDelete className="fs-5 toggleicon" />{" "}
            <span className="toggle px-2">Delete publication </span>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between py-3 px-2">
          <p className="collectionheader">Publications</p>
        </div>
        <div className="">
          <div className="rounded bwhite px-4 py-1">
            <div className="w-25 form-group">
              <Label className="fs-12">MY Publication</Label>
              <div className="form-control-wrap">
                <input
                  className="form-control customfont searchtext"
                  type="text"
                  id="default-2"
                  autoComplete="off"
                  placeholder="Search here"
                  autoFocus
                  onChange={searchFlipBook}
                />
              </div>
            </div>
          </div>
        </div>

        {data ? (
          data.map((item, idx) => (
            <div class="container py-3">
              <div class="row card-layout">
                <div className="col-1">
                  <div class="qr-code">
                    <img src={item?.thumbnail} alt="Icon" height={60} />
                  </div>
                </div>
                <div class="col-md-2 text-section">
                  <div>
                    <VscBook className="font" />
                    <span className="px-2 publishtitle">{item?.title}</span>
                  </div>
                  <div>
                    <span className="createdtext">{item.created_at}</span>
                  </div>
                </div>
                {process.env.REACT_APP_TITLE === "1" ? (
                  <div className="col-md-4 d-flex justify-content-center">
                    <span class="fwbold">POWERED BY SILOCLOUD</span>
                  </div>
                ) : (
                  <div className="col-md-4 d-flex justify-content-center">
                    <span class="fwbold">POWERED BY AXIOMWEBCONTROL</span>
                  </div>
                )}
                <div class="col-md-4 justify-content-center button-group">
                  {process.env.REACT_APP_TITLE === "1" ? (
                    <a
                      download
                      class="btn btn-outline-danger"
                      href={item.download_path}
                    >
                      Download
                    </a>
                  ) : (
                    <a
                      download
                      class="btn btn-outline-primary"
                      href={item.download_path}
                    >
                      Download
                    </a>
                  )}
                  <div>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      show={showPopover === item.id}
                      onToggle={() => {
                        setShowPopover(
                          showPopover === item.id ? null : item.id
                        );
                        setFlipBookId(item?.flipbook_id);
                      }}
                      overlay={popover}
                    >
                      {process.env.REACT_APP_TITLE === "1" ? (
                        <button type="button" className="btn btn-danger mx-3">
                          <BsThreeDots />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn gradient-button mx-3"
                        >
                          <BsThreeDots />
                        </button>
                      )}
                    </OverlayTrigger>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => navigateToPublish(item.flipbook_id)}
                  >
                    <SlEye className="font  tooltip-trigger" />
                    <span className="tooltip-text">View</span>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => navigateToPublish(item.flipbook_id)}
                  >
                    <CiEdit className="font  tooltip-trigger" />
                    <span className="tooltip-text">Edit</span>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => handleShare(item.id)}
                  >
                    <AiOutlineShareAlt className="font tooltip-trigger" />
                    <span className="tooltip-text">Share</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-4">
            {[...Array(3)].map((_, index) => (
              <ShimmerCategoryItem
                key={index}
                hasImage
                imageType="thumbnail"
                imageWidth={100}
                imageHeight={100}
                text
                cta
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
