import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { VscBook } from "react-icons/vsc";
import Publications from "./Publications";
import Followers from "./Followers";
import { SlUserFollow } from "react-icons/sl";
import { GoShareAndroid } from "react-icons/go";
import { AiOutlineEdit } from "react-icons/ai";
import profileimage from "../Images/profile.png";
import { getCookie } from "./Utils";
import { Link, useParams } from "react-router-dom";

const Publicprofile = () => {
  const [toggle, setToggle] = useState(1);
  console.log(toggle);

  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const [count, setCount] = useState(0);
  const [data, setData] = useState(null);
  const { username } = useParams();
  useEffect(() => {
    fetchRecentData();
  }, []);

  const fetchRecentData = async () => {
    try {
      // const username = getCookie("username");
      const formData = new FormData();
      formData.append("username", username);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publisher-profile`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data);
      // setCount(responseData?.data.allPublicationsCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Profile",
          text: "Check out this flipbook!",
          url: `${process.env.REACT_APP_PUBLIC_URL}profile`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Share not supported on this browser");
    }
  };

  return (
    <div className="container-fluid publicprofile">
      <>
        {data && (
          <div className="row py-5">
            <div className="col-md-2">
              <div className="profile-pic d-flex justify-content-center">
                <div className="profile-circle">
                  <img
                    src={data.profile_image_path || profileimage}
                    // src={profileimage}
                    alt=""
                    className="roundedcircle"
                    width={180}
                  />
                </div>
              </div>
                <div className="py-2 d-flex justify-content-center">
                <Link to="https://accounts.silocloud.io/">
                  <button type="button" class="btn btn-primary btnpadding">
                    <AiOutlineEdit className="btnlogo" />
                    <span>Edit Profile</span>
                  </button>
                  </Link>
                </div>
              <div className="d-flex justify-content-center">
                <button type="button" class="btn btn-dark" onClick={() => handleShare()}>
                  <GoShareAndroid className="btnlogo" />
                  <span>Share Profile</span>
                </button>
              </div>
            </div>
            <div className="col-md-10">
              <h1>{data.username}</h1>
              {/* <h1>Mayur Patil</h1> */}
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="my-3 d-flex justify-content-between">
            <Nav tabs className="p-4 nav-tabs">
              <NavItem className="px-1">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({
                    active: activeIconTab === "1",
                  })}
                  onClick={(ev) => {
                    setToggle(1);
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <VscBook className="fs-4 publicationtitle" />{" "}
                  <span className="navlinktext publicationtitle px-1">
                    Publications ({data ? data.publication_count : "0"})
                  </span>
                </NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({
                    active: activeIconTab === "2",
                  })}
                  onClick={(ev) => {
                    setToggle(2);
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <SlUserFollow className="publicationtitle" />
                  <span className="navlinktext px-2 publicationtitle">
                    Followers (0)
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div>
            {toggle === 1 ? (
              <Publications publicationdata={data} />
            ) : (
              <Followers />
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default Publicprofile;
